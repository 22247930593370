<template>
  <div class="ggh-link" @click="goOut(props.link)" :title="props.title"><slot /></div>
</template>

<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  link: {
    type: String
  },
  title: {
    type: String
  },
  target: {
    type: String
  }
})

const goOut = link => {
  if (props.target === '_blank') {
    window.open(link, '_blank')
  } else {
    location.href = link
  }
}
</script>

<style lang="scss" scoped>
.ggh-link {
  cursor: pointer;
}
</style>
