import store from '@/store'

const secData = [
  { string: 'something\\_else?', align: 'right', extra: ['//something else?', '//其他玩意？', '//something else?', '//其他玩意？', '//something else?', '//其他玩意？'] },
  { string: 'game_\\dreamer', align: 'left', extra: ['using System;', 'using Googangheim;', 'public class Player : GoogangheimObject {', 'void Coo() {', 'Debug.log("Coo coo coo~")', '}', '}'] },
  { string: 'code_\\blaster', align: 'right', extra: ['#include<bits/stdc++.h>', '#include<googangheim.h>', 'using namespace std;', 'int main() {', '\xa0\xa0\xa0\xa0cout << "Coo coo coo~";', '\xa0\xa0\xa0\xa0return 0;', '}'] },
  { string: 'linguistic\\_teatime', align: 'left', extra: ['//Welcome to Googangheim!', '//Huonêng loe Googangheim!', '//欢迎来到咕哽家！', '//Welcome to Googangheim!', '//Huonêng loe Googangheim!', '//欢迎来到咕哽家！'] }
]

export function validateSection (title) {
  let idx = store.state.section.lastIndexOf(title)
  if (idx === -1) idx = 0
  return {
    title: store.state.section[idx],
    ...secData[idx]
  }
}
