<template>
  <transition name="ggh-mask-fade">
    <div class="ggh-mask full-window" v-show="visible" @click="visible = stable" />
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue'
// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  stable: {
    type: Boolean,
    default: false
  }
})
// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue'])
const visible = ref(false)
watch(props, newValue => {
  visible.value = newValue.modelValue
})
watch(visible, newValue => {
  emit('update:modelValue', newValue)
})
</script>

<style lang="scss">
.ggh-mask {
  background: black;
  opacity: 60%;
}
.ggh-mask-fade-enter-active {
  animation: ggh-mask-fade-load 150ms;
}

.ggh-mask-fade-leave-active {
  animation: ggh-mask-fade-load 150ms reverse;
}

@keyframes ggh-mask-fade-load {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}
</style>
