import { createStore } from 'vuex'
import loading from './loading'

export default createStore({
  state: {
    section: ['something-else', 'game-dreamer', 'code-blaster', 'linguistic-teatime']
  },
  modules: {
    loading
  }
})
