import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'

import enUSLocale from './en-US'
import zhCNLocale from './zh-CN'
import zlZCLocale from './zl-ZC'

const messages = {
  'en-US': enUSLocale,
  'zh-CN': zhCNLocale,
  'zl-ZC': zlZCLocale
}

export const i18n = createI18n({
  legacy: false,
  locale: Cookies.get('locale') || 'en-US',
  fallbackLocale: 'en-US',
  messages
})

function useLocales (app) {
  app.use(i18n)
}

export function changeLocale (locale) {
  Cookies.set('locale', locale, { expires: 30 })
  location.reload()
}

export default useLocales
