<template>
  <div class="content-box">
    <ggh-tablet
      width="100%"
      :y-start="1333"
      :y-duration="1000"
      :x-duration="1000"
      ><div class="main-content">
        <ggh-breadcrumb class="border-btm" />
        <div class="content-wrapper"><router-view /></div>
      </div>
    </ggh-tablet>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "@/assets/css";

.content-box {
  clear: both;
  .main-content {
    padding: 15px 20px;
    .ggh-breadcrumb {
      padding-bottom: 15px;
    }
  }
}
</style>
