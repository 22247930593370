<template>
  <div class="ggh-ht">
    <ggh-string v-if="gString" :string="gString" :scale="scale" />
    <span v-else><slot /></span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import gghString from '../String'
// eslint-disable-next-line no-undef
const props = defineProps({
  gString: {
    type: String,
    default: ''
  },
  scale: {
    type: Number,
    default: 1
  }
})

const theme = computed(() => {
  return {
    fontSize: 20 * props.scale + 'px'
  }
})
</script>

<style lang="scss">
@import '@/assets/css';

.ggh-ht {
  width: fit-content;
  span {
    color: $ggh-dark;
    font-weight: bold;
    font-size: v-bind("theme.fontSize");
  }
}
</style>
