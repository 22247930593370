<template>
  <div class="ggh-message-box full-window">
    <ggh-mask stable style="z-index: -1" v-model="visible" />
    <transition @before-leave="onClose" @after-leave="onDestroy" name="message-box-morph">
      <div class="message-box-wrapper" v-if="visible">
        <div class="mb-border" />
        <div class="mb-edge" />
        <div class="mb">
          <ggh-string :string="title" :scale="2" v-if="gString" />
          <h1 v-else>{{ title }}</h1>
        </div>
        <div class="mb">
          <props.content />
        </div>
        <div class="mb mb-options">
          <ggh-button v-for="event in events" :key="event.name" @click="eventHandle(event.func)">
            {{ event.name }}
          </ggh-button>
        </div>
        <div class="mb-edge" />
        <div class="mb-border" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, toRefs } from 'vue'
import gghButton from '../Button'
import gghMask from '../Mask'
import gghString from '../String'
// eslint-disable-next-line no-undef
const props = defineProps({
  title: {
    type: String,
    default: 'TITLE'
  },
  gString: {
    type: Boolean,
    default: false
  },
  content: {
    type: Object
  },
  events: {
    type: Array,
    default: () => []
  },
  cancel: {
    type: String
  },
  duration: {
    type: Number,
    default: 3000
  },
  onDestroy: Function,
  onClose: Function
})

const { title, events, cancel } = toRefs(props)
const visible = ref(false)
const eventHandle = (event) => {
  if (event) event()
  visible.value = false
}

onBeforeMount(() => {
  if (cancel.value) {
    events.value.push({ name: cancel.value })
  }
})

onMounted(() => {
  visible.value = true
})
</script>

<style lang="scss">
@import "@/assets/css";

.ggh-message-box {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: $ggh-bottom;

  .message-box-wrapper {
    height: 50%;
    width: 100%;
    background: $ggh-dark;
    display: flex;
    flex-direction: column;

    div {
      overflow: hidden;
    }

    .mb-border {
      height: 8px;
      width: 100%;
      background: $ggh-bottom;
      margin: 4px 0;
    }

    .mb-edge {
      height: 64px;
      width: 100%;
      transform: rotate(180deg);
      background: url("./ggh-mb-edge.png");
      background-size: contain;
    }

    .mb {
      margin: auto;
    }

    .mb-options {
      display: flex;
      justify-content: space-around;
      width: 50%;
    }
  }
}

.message-box-morph-enter-active {
  animation: message-box-morph-load 300ms;
}

.message-box-morph-leave-active {
  animation: message-box-morph-load 300ms reverse;
}

@keyframes message-box-morph-load {
  from {
    height: 0px;
  }

  to {
    height: 50%;
  }
}
</style>
