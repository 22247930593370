<template>
  <div class="option-list">
    <div class="re side" />
    <div class="mid" :style="{ height: 1 * props.scale + 'px' }" />
    <div class="mid" :style="{ 'height': height + 'px' }" ref="showRef">
      <div ref="midRef" class="translate">
        <div v-show="props.length">
          <div class="option-item"
            v-for="item in length" :key="item"
            :class="{ 'show-item': shown >= 35 * props.scale * item }"
          >
            <slot :name="'item' + item" />
          </div>
        </div>
      </div>
    </div>
    <div class="side" />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import elementResizeDetectorMaker from 'element-resize-detector'
// eslint-disable-next-line no-undef
const props = defineProps({
  length: {
    type: Number,
    default: 0
  },
  scale: {
    type: Number,
    default: 1
  }
})

const theme = computed(() => {
  return {
    move: `translateY(${16 * props.scale}px)`,
    padding: 4 * props.scale + 'px',
    height: 16 * props.scale + 'px',
    width: 33 * props.scale + 'px'
  }
})

const midRef = ref()
const showRef = ref()
const height = ref(0)
const shown = ref(0)
const erd = elementResizeDetectorMaker()
const detectRef = () => {
  nextTick(() => {
    erd.listenTo(midRef.value, (element) => {
      height.value = element.clientHeight
    })
  })
  nextTick(() => {
    erd.listenTo(showRef.value, (element) => {
      shown.value = element.clientHeight
    })
  })
}

onMounted(() => {
  detectRef()
})
</script>

<style lang="scss" scoped>
.option-list {
  width: v-bind("theme.width");
  transform-origin: 0% 0%;
  .side {
    height: v-bind("theme.height");
    background: url(./images/background.png);
    background-size: cover;
  }
  .re {
    transform: rotate(180deg);
  }
  .mid {
    background: #fff;
    color: #000;
    transition-property: height;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    height: 0px;
    .translate {
      transform: v-bind("theme.move");
      z-index: 10;
    }
    .option-item {
      padding: v-bind("theme.padding");
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transition-property: filter, -webkit-filter;
      transition-duration: 500ms, 500ms;
    }
    .show-item {
      filter: opacity(100%) !important;
      -webkit-filter: opacity(100%) !important;
    }
  }
}
</style>
