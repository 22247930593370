<template>
  <el-tree class="sec-tree" :data="data" :props="defaultProps" accordion />
</template>

<script setup>
// eslint-disable-next-line no-undef
const props = defineProps({
  section: {
    type: String,
    default: 'something-else'
  }
})

const data = [
  {
    label: props.section + '1',
    children: [
      {
        label: props.section + '1-1',
        children: [
          {
            label: props.section + '1-1-1'
          }
        ]
      }
    ]
  },
  {
    label: props.section + '2',
    children: [
      {
        label: props.section + '2-1',
        children: [
          {
            label: props.section + '2-1-1'
          }
        ]
      },
      {
        label: props.section + '2-2',
        children: [
          {
            label: props.section + '2-2-1'
          }
        ]
      }
    ]
  },
  {
    label: props.section + '3',
    children: [
      {
        label: props.section + '3-1',
        children: [
          {
            label: props.section + '3-1-1'
          }
        ]
      },
      {
        label: props.section + '3-2',
        children: [
          {
            label: props.section + '3-2-1'
          }
        ]
      }
    ]
  }
]

const defaultProps = {
  children: 'children',
  label: 'label'
}
</script>

<style lang="scss">
@import "@/assets/css";

.sec-tree {
  .el-tree-node__content {
    background: $ggh-bottom;
    color: $ggh-light;
    svg {
      color: $ggh-light;
    }
    &:hover {
      background: $ggh-dark;
      color: $ggh-bottom;
      svg {
        color: $ggh-bottom;
      }
    }
  }
}
</style>
