<template>
  <div class="comment-box">
    <ggh-tablet width="100%">
      <div class="comment-header">
        <span>收起</span>
        <span style="font-size: 20px">评论</span>
        <span>留言</span>
      </div>
      <el-scrollbar>
        <comment-item
          v-for="comment in commentList"
          :key="comment.replyId"
          :item="comment"
        />
      </el-scrollbar>
    </ggh-tablet>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CommentItem from './CommentItem.vue'

const commentList = ref([
  { replyId: 12, floor: 2, email: '114514@homo.com', time: '2022/07/17 11:45:14', content: <div>你是一个，一个一个一个一个，啊啊啊啊啊啊啊啊啊啊啊啊</div> }
])
</script>

<style lang="scss" scoped>
.comment-box {
  .comment-header {
    font-size: 14px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
