<template>
  <div class="ggh-loading">
    <div class="text" />
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import '@/assets/css';

.ggh-loading {
  background: $ggh-dark;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .text {
    width: 342px;
    height: 46px;
    margin: 8px;
    position: fixed;
    right: 0;
    bottom: 0;
    background: url("./ggh-loading.gif");
    background-size: contain;
  }
}
</style>
