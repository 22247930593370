<template>
  <div class="ggh-tablet" :class="{ reverse: props.reversed }">
    <div class="expansion" :class="{ 'show-x': xExpanded, 'show-y': yExpanded }">
      <ggh-border height="100%" width="100%" :hide="props.hide" />
    </div>
    <div ref="coreRef" :class="{ reverse: props.reversed }" :style="styleObj">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import elementResizeDetectorMaker from 'element-resize-detector'
import gghBorder from '../Border'

// eslint-disable-next-line no-undef
const props = defineProps({
  height: {
    type: String
  },
  xStart: {
    type: Number,
    default: 0
  },
  xDuration: {
    type: Number,
    default: 0
  },
  width: {
    type: String
  },
  yStart: {
    type: Number,
    default: 0
  },
  yDuration: {
    type: Number,
    default: 0
  },
  reversed: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Object,
    default: () => ({})
  }
})

const coreRef = ref()
const refHeight = ref(0)
const refWidth = ref(0)
const state = ref(0)
const xExpanded = ref(false)
const yExpanded = ref(false)

const expand = async (axis, start, duration) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      axis.value = true
      resolve()
    }, start)
  }).then(() => {
    setTimeout(() => {
      state.value += 1
    }, duration)
  })
}

const erd = elementResizeDetectorMaker()
const initialize = () => {
  nextTick(() => {
    erd.listenTo(coreRef.value, (element) => {
      refHeight.value = element.clientHeight
      refWidth.value = element.clientWidth
      console.log(refHeight.value, refWidth.value)
    })
  }).then(() => {
    expand(xExpanded, props.xStart, props.xDuration)
    expand(yExpanded, props.yStart, props.yDuration)
  })
}

const height = computed(() => {
  return !props.height
    ? refHeight.value
      ? `${refHeight.value}px`
      : 'fit-content'
    : props.height
})
const width = computed(() => {
  return !props.width
    ? refWidth.value
      ? `${refWidth.value}px`
      : 'fit-content'
    : props.width
})

const styleObj = computed(() => {
  return {
    padding: '10px',
    opacity: state.value >= 2 ? 1 : 0,
    transition: `opacity ${props.xDuration || props.yDuration ? '500' : '0'}ms`
  }
})

const theme = computed(() => {
  return {
    height: height.value,
    width: width.value,
    xDuration: props.xDuration + 'ms',
    yDuration: props.yDuration + 'ms'
  }
})

onMounted(async () => {
  initialize()
})
</script>

<style lang="scss" scoped>
.ggh-tablet {
  height: v-bind("theme.height");
  width: v-bind("theme.width");
  position: relative;
  transition-property: height, width;
  transition-duration: v-bind("theme.yDuration"), v-bind("theme.xDuration");

  .expansion {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    transition-property: height, width;
    transition-duration: v-bind("theme.yDuration"), v-bind("theme.xDuration");
  }

  .show-x {
    width: 100%;
  }

  .show-y {
    height: 100%;
  }
}

.reverse {
  transform: rotateY(180deg);
}
</style>
