<template>
  <div class="options">
    <div class="opt options-icon ggh-hover-brightened" @click="activateOptions" />
    <div class="down">
      <option-list :length="isActive * 4" :scale="props.scale">
        <template #item1>
          <sec-option title="game-dreamer" :scale="props.scale" @click="activateOptions" />
        </template>
        <template #item2>
          <sec-option title="code-blaster" :scale="props.scale" @click="activateOptions" />
        </template>
        <template #item3>
          <sec-option title="linguistic-teatime" :scale="props.scale" @click="activateOptions" />
        </template>
        <template #item4>
          <sec-option :scale="props.scale" @click="activateOptions" />
        </template>
      </option-list>
    </div>
    <div class="opt left">
      <option-list :length="isActive * 4" :scale="props.scale">
        <template #item1>
          <subscribe-option :scale="props.scale" @click="activateOptions" />
        </template>
        <template #item2>
          <share-option :scale="props.scale" />
        </template>
        <template #item3>
          <language-option :scale="props.scale" />
        </template>
        <template #item4>
          <search-option :scale="props.scale" @click="activateOptions" />
        </template>
      </option-list>
    </div>
    <ggh-mask v-model="isActive" style="z-index: -1" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import OptionList from './OptionList'
import LanguageOption from './items/LanguageOption'
import SearchOption from './items/SearchOption'
import ShareOption from './items/ShareOption'
import SecOption from './items/SecOption'
import SubscribeOption from './items/SubscribeOption'
// eslint-disable-next-line no-undef
const props = defineProps({
  scale: {
    type: Number,
    default: 1
  }
})

const theme = computed(() => {
  return {
    height: 29 * props.scale + 'px',
    width: 27 * props.scale + 'px',
    margin: 2 * props.scale + 'px ' + 3 * props.scale + 'px',
    leftTrans: 33 * props.scale + 'px'
  }
})

const isActive = ref(false)

const activateOptions = () => {
  isActive.value = !isActive.value
}
</script>

<style lang="scss">
.opt {
  position: absolute;
  top: 0;
}
.options {
  position: relative;
  .options-icon {
    margin: v-bind("theme.margin");
    height: v-bind("theme.height");
    width: v-bind("theme.width");
    background: url(./images/options-bigger.png);
    background-size: cover;
    z-index: 10;
  }
  .left {
    transform: rotate(90deg) translateX(v-bind("theme.leftTrans"));
    transform-origin: 100% 0 0;
    .option-item {
      transform: rotate(-45deg)
    }
  }
}
</style>
