import { createRouter, createWebHistory } from 'vue-router'
import { i18n } from '@/locales'
import Layout from '@/layout'

const routes = [
  {
    path: '/',
    name: 'lobby',
    component: Layout,
    meta: {
      notAttached: true
    },
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/views'),
        meta: {
          noContent: true,
          notAside: true
        }
      },
      {
        path: '/game-dreamer',
        name: 'game-dreamer',
        component: () => import('@/views/Googangsite'),
        meta: {
          title: '游戏梦想家',
          intro: '这里记录了Googangheim的游戏大业的发展历程（雾'
        },
        children: [
          {
            path: 'dot-to-pic/:article?',
            name: 'dot-to-pic',
            component: () => import('@/views/Googangsite')
          },
          {
            path: 'pic-to-sprite/:article?',
            name: 'pic-to-sprite',
            component: () => import('@/views/Googangsite')
          },
          {
            path: 'sprite-to-scene/:article?',
            name: 'sprite-to-scene',
            component: () => import('@/views/Googangsite')
          }
        ]
      },
      {
        path: '/code-blaster/:article?',
        name: 'code-blaster',
        component: () => import('@/views/Googangsite')
      },
      {
        path: '/linguistic-teatime',
        name: 'linguistic-teatime',
        component: () => import('@/views/Googangsite'),
        children: [
          {
            path: 'european/:article?',
            name: 'european-languages',
            component: () => import('@/views/Googangsite')
          },
          {
            path: 'east-asian/:article?',
            name: 'asian-languages',
            component: () => import('@/views/Googangsite')
          },
          {
            path: 'zeclands',
            name: 'project-zeclands',
            beforeEnter: () => {
              location.href = 'http://zecci.googangheim.me'
            }
          }
        ]
      },
      {
        path: '/something-else',
        name: 'something-else',
        component: () => import('@/views/Googangsite'),
        children: [
          {
            path: 'tricks',
            name: 'small-tricks',
            component: () => import('@/views/Googangsite/Tricks')
          },
          {
            path: 'logs',
            name: 'googangsite-logs',
            component: () => import('@/views/Googangsite/Logs')
          },
          {
            path: 'settings',
            name: 'settings',
            component: () => import('@/views/Googangsite/Settings')
          },
          {
            path: 'about',
            name: 'about-me',
            component: () => import('@/views/Googangsite/About'),
            meta: {
              notInTree: true
            }
          },
          {
            path: 'links',
            name: 'friend-links',
            component: () => import('@/views/Googangsite/Links'),
            meta: {
              notInTree: true
            }
          },
          {
            path: 'contact',
            name: 'contact-me',
            component: () => import('@/views/Googangsite/Contact'),
            meta: {
              notInTree: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/test',
    name: 'test',
    beforeEnter: () => {
      if (process.env.NODE_ENV !== 'development') {
        location.href = 'http://googangheim.me'
      }
    },
    component: () => import('@/views/testView')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'index') {
    document.title = 'Welcome to Googangheim!'
  } else {
    document.title = `${i18n.global.t(to.name + '.title')} | Googangheim`
  }
  next()
})

export function getChildren (name, ancestor) {
  if (!ancestor) ancestor = 'lobby'
  let res = []
  routes.forEach(ele => {
    if (ele.name === ancestor) {
      ele.children.forEach(eleme => {
        if (eleme.name === name) {
          if (eleme.children) res = eleme.children
          return false
        }
      })
      return false
    }
  })
  return res
}

export default router
