<template>
  <div class="ggh-border">
    <table class="over-border" cellpadding="0px" cellspacing="0px">
      <tr>
        <td class="border-corner" :class="{ 'show-border-corner': !props.hide.a1 }" />
        <td class="border-x" :class="{ 'show-border-x': !props.hide.borderX }" />
        <td class="border-corner" :class="{ 'show-border-corner a2': !props.hide.a2 }" />
      </tr>
      <tr>
        <td class="border-y" :class="{ 'show-border-y': !props.hide.borderY }" />
        <td class="center" />
        <td class="border-y" :class="{ 'show-border-y': !props.hide.borderY }" />
      </tr>
      <tr>
        <td class="border-corner" :class="{ 'show-border-corner a4': !props.hide.a4 }" />
        <td class="border-x" :class="{ 'show-border-x': !props.hide.borderX }" />
        <td class="border-corner" :class="{ 'show-border-corner a3': !props.hide.a3 }" />
      </tr>
    </table>
    <div class="box-core">
      <slot />
      <div class="inside-corner b1"><div v-for="i in 3" :key="i" class="inside-dot" /></div>
      <div class="inside-corner b2 a2"><div v-for="i in 3" :key="i" class="inside-dot" /></div>
      <div class="inside-corner b3 a3"><div v-for="i in 3" :key="i" class="inside-dot" /></div>
      <div class="inside-corner b4 a4"><div v-for="i in 3" :key="i" class="inside-dot" /></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// eslint-disable-next-line no-undef
const props = defineProps({
  height: {
    type: String,
    default: 'fit-content'
  },
  width: {
    type: String,
    default: 'fit-content'
  },
  hide: {
    type: Object,
    default: () => ({})
  }
})

const theme = computed(() => {
  return {
    height: props.height && props.height.match('\\d+(px)|\\%') ? `calc(${props.height} - 20px` : 'fit-content',
    width: props.width && props.width.match('\\d+(px)|\\%') ? `calc(${props.width} - 20px` : 'fit-content'
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css';

.ggh-border {
  height: v-bind("theme.height");
  width: v-bind("theme.width");
  position: relative;
  padding: 10px;

  .over-border {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .border-x {
      height: 10px;
      background-color: $ggh-bottom;
    }

    .show-border-x {
      background-image: url("./images/x.png");
    }

    .border-corner {
      height: 10px;
      width: 10px;
      background: $ggh-bottom;
    }

    .show-border-corner {
      background: url("./images/corner.png") !important;
    }

    .border-y {
      width: 10px;
      height: 100%;
      background-color: $ggh-bottom;
    }

    .show-border-y {
      background-image: url("./images/y.png");
    }

    .center {
      background: $ggh-bottom;
    }

  }

  .box-core {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $ggh-bottom;
    transform: translateZ(10px);
    position: relative;

    .inside-corner {
      height: 4px;
      width: 4px;
      display: flex;
      flex-wrap: wrap;
      .inside-dot {
        height: 2px;
        width: 2px;
        background: $ggh-bottom;
      }
    }

  }
}

.a2 {
  transform: rotate(90deg);
}

.a3 {
  transform: rotate(180deg);
}

.a4 {
  transform: rotate(-90deg);
}

.b1 {
  position: absolute;
  top: 0;
  left: 0;
}

.b2 {
  position: absolute;
  top: 0;
  right: 0;
}

.b3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.b4 {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
