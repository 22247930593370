<template>
  <router-view/>
  <div class="app-version">v0.1</div>
</template>

<style lang="scss">
@import '@/assets/css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-version {
  position: fixed;
  top: 10px;
  left: 10px;
  color: $ggh-dark;
  font-weight: bold;
  text-shadow: 1px 1px $ggh-bottom;
}
</style>
