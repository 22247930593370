const dictionary = '0123456789abcdefghijklmnopqrstuvwxyz()[]{}<>!^-_;:\'",./?'
export function getCharData (char) {
  if (char === ' ') return -2
  return dictionary.lastIndexOf(char.toLowerCase())
}

const validStyles = ['up', 'down']
export function validHighlight (style) {
  return validStyles.lastIndexOf(style) !== -1
}
