<template>
  <div class="ggh-info" :style="styleObj">
    © GOOGANGHEIM 2022, all rights reserved. |
     <span class="links" @click="() => { $router.push({name: 'about-me'}) }">About</span> |
     <span class="links" @click="() => { $router.push({name: 'friend-links'}) }">Links</span> |
     <span class="links" @click="() => { $router.push({name: 'contact-me'}) }">Contact</span>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
// eslint-disable-next-line no-undef
const props = defineProps({
  abs: {
    type: String,
    default: ''
  }
})

const styleObj = ref({})

onMounted(() => {
  if (props.abs) {
    styleObj.value.position = 'absolute'
    styleObj.value[props.abs] = '0'
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/css';

.ggh-info {
  width: 100%;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px black;
  text-align: center;
  margin: 10px auto;
  .links {
    cursor: pointer;
    &:hover {
      color: $ggh-dark
    }
  }
}
</style>
