<template>
  <div class="subscribe-option">
    <el-tooltip :content="$t('subscribe').toLocaleUpperCase()" placement="bottom" effect="light">
      <items image="subscribe" :scale="props.scale" @click="openSubscribe" />
    </el-tooltip>
  </div>
</template>

<script setup>
import { GghMessageBox } from '@/components/ggh'

import items from './'
// eslint-disable-next-line no-undef
const props = defineProps({
  scale: {
    type: Number,
    default: 1
  }
})

const openSubscribe = () => {
  GghMessageBox({
    title: 'SUBSCRIBE',
    content: <div>IN DEVELOPMENT</div>,
    gString: true,
    cancel: 'CANCEL'
  })
}
</script>

<style lang="scss" scoped>

</style>
