<template>
  <div class="section-item"
    @mouseover="hovered = true"
    @mouseleave="hovered = chosen"
  >
    <div class="section-box">
      <ggh-tablet style="margin: auto;"
        width="100%" :y-duration="chosen * showHeightDur">
        <div>
          <div :style="extraStyle">
            <div v-for="line in secData.extra" :key="line">{{line}}</div>
          </div>
          <div class="tree-box" v-if="showTree">
            <tree-box :section="props.title" />
          </div>
        </div>
      </ggh-tablet>
    </div>
    <div class="button" @click="() => {if(!chosen) $router.push({ name: props.title })}">
      <div
        class="image-wrapper"
        :class="{ 'image-hover': hovered }"
      />
      <div class="string-wrapper" :style="{ 'float': secData.align }">
        <ggh-string
          hide-light-when-idle
          :string="secData.string"
          :scale="props.scale"
          :highlights="['down', 'up']"
          :alignment="secData.align"
          v-model:string-hovered="hovered"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { validateSection } from './sectionData'
import TreeBox from './TreeBox'
// eslint-disable-next-line no-undef
const props = defineProps({
  scale: {
    type: Number,
    default: 1
  },
  title: {
    type: String,
    default: 'something-else'
  },
  alignment: {
    type: String,
    default: 'left'
  }
})

const hovered = ref(false)
const route = useRoute()
const chosen = computed(() => {
  return route.matched[1] && route.matched[1].name === props.title
})
const showTree = computed(() => {
  return chosen.value && route.name !== props.title
})
const showHeightDur = ref(false)

watch(showTree, newValue => {
  showHeightDur.value = 1000
  setTimeout(() => {
    showHeightDur.value = null
  }, 1000)
})

const secData = computed(() => {
  return validateSection(props.title)
})

const imgHeight = ref(52)
const imgWidth = ref(164)

const theme = computed(() => {
  return {
    height: imgHeight.value * props.scale + 'px',
    width: imgWidth.value * props.scale + 'px',
    backgroundOff: 'url(' + require(`./images/${secData.value.title}/dark.png`) + ')',
    backgroundOn: 'url(' + require(`./images/${secData.value.title}/light.png`) + ')',
    padding: 2 * props.scale + 'px',
    stringBackground: 'url(' + require(`./images/${secData.value.title}/background.png`) + ')',
    boxWidth: `calc(100% - ${16 * props.scale}px)`,
    boxLeft: 8 * props.scale + 'px'
  }
})

watch(chosen, newValue => {
  nextTick(() => {
    hovered.value = newValue
  })
})

const extraStyle = computed(() => {
  return {
    height: props.scale * 40 - 14 + 'px',
    padding: props.scale * 2 + 'px',
    textAlign: secData.value.align === 'left' ? 'right' : 'left',
    fontFamily: 'Console',
    fontSize: '12px',
    color: '#404144',
    lineHeight: '18px',
    overflow: chosen.value && 'hidden'
  }
})
</script>

<style lang="scss" scoped>
.section-item {
  width: v-bind("theme.width");
  position: relative;
  .button {
    cursor: pointer;
    transform: translateZ(1rem);
    .image-wrapper {
      height: v-bind("theme.height");
      width: 100%;
      background: v-bind("theme.backgroundOff");
      background-size: cover;
    }
    .image-hover {
      background: v-bind("theme.backgroundOn");
      background-size: cover;
    }
    .string-wrapper {
      width: fit-content;
      padding: v-bind("theme.padding");
      background: v-bind("theme.stringBackground");
      background-size: cover;
    }
  }
  .section-box {
    width: v-bind("theme.boxWidth");
    position: absolute;
    top: v-bind("theme.height");
    left: v-bind("theme.boxLeft");
    .tree-box {
      width: 100%;
      padding-top: v-bind("theme.padding");
    }
  }
}
</style>
