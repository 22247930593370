<template>
  <div class="comment-item">
    <ggh-border width="100%" :hide="{ borderX: true }">
      <div class="slot-content">
        <div class="info options">
          <span>{{ item.email }}</span>
          <span>#{{ item.floor }}</span>
        </div>
        <div class="comment-content ggh-hover-brightened" @click="reply(item.replyId)">
          <comment-content />
        </div>
        <div class="options">
          <span>{{ item.time }}</span>
          <span>查看回复</span>
        </div>
      </div>
    </ggh-border>
  </div>
</template>

<script setup>
import { ref, toRefs } from 'vue'
// eslint-disable-next-line no-undef
const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const { item } = toRefs(props)
const commentContent = ref(item.value.content)

const reply = (id) => {
  // console.log(id)
}
</script>

<style lang="scss" scoped>
.comment-item {
  .slot-content {
    width: 100%;
    .info {
      font-size: 14px !important;
    }
    .options {
      display: flex;
      padding: 0 5px;
      justify-content: space-between;
      font-size: 12px;
    }
    .comment-content {
      padding: 10px;
      color: #cfd3dc;
      width: auto;
      height: auto;
      text-align: left;
    }
  }
}
</style>
