<template>
  <div class="ggh-string">
    <div v-for="(line, lineIdx) in string" :key="lineIdx" class="line" :style="alignment">
      <ggh-char
        v-for="(char, charIdx) in line"
        :key="lineIdx + '-' + charIdx"
        :char="char"
        :highlight="highlights[lineIdx % highlights.length]"
        :scale="scales[lineIdx % scales.length]"
        :hide-light-when-idle="props.hideLightWhenIdle"
        v-model:char-hovered="hovered"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { validHighlight } from '../Char/charData'
import { validAlignment } from './stringData'
import gghChar from '../Char'

// eslint-disable-next-line no-undef
const props = defineProps({
  string: {
    type: String,
    required: true
  },
  highlight: {
    type: String,
    default: 'none'
  },
  highlights: {
    type: Array,
    default: () => ['none']
  },
  scale: {
    type: Number,
    default: 1
  },
  scales: {
    type: Array,
    default: () => [1]
  },
  alignment: {
    type: String,
    default: 'left'
  },
  stringHovered: {
    type: Boolean,
    default: false
  },
  hideLightWhenIdle: {
    type: Boolean,
    default: false
  }
})

const string = computed(() => {
  const res = []
  props.string.split('\\').forEach((element) => {
    res.push(element.split(''))
  })
  return res
})

const highlights = computed(() => {
  let res = [props.highlight]
  props.highlights.forEach((element) => {
    if (validHighlight(element)) {
      res = props.highlights
      return true
    }
  })
  return res
})

const scales = computed(() => {
  const res = []
  props.scales.forEach((element) => {
    res.push(element * props.scale)
  })
  return res
})

const alignment = computed(() => {
  return {
    justifyContent: validAlignment(props.alignment)
  }
})
// eslint-disable-next-line no-undef
const emit = defineEmits(['update:stringHovered'])

const hovered = ref(false)
watch(props, newValue => {
  hovered.value = newValue.stringHovered
})
watch(hovered, newValue => {
  emit('update:stringHovered', newValue)
})
</script>

<style lang="scss" scoped>
.ggh-string {
  width: fit-content;
  display: flex;
  flex-direction: column;
  .line {
    display: flex;
    flex-direction: row;
  }
}
</style>
