<template>
  <div class="ggh-breadcrumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="item in levelList" :key="item.name">
        <span v-if="item.redirect === 'noRedirect'">{{ $t(item.name + '.title') }}</span>
        <a v-else @click="handleLink(item)">{{ $t(item.name + '.title') }}</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
watch(route, () => {
  getBreadcrumb()
})

const levelList = ref([])
const getBreadcrumb = () => {
  levelList.value = route.matched.filter(
    item => !item.meta.notAttached
  )
  if (route.params && route.params.article) {
    levelList.value.push({
      name: route.params.article,
      redirect: 'noRedirect'
    })
  }
}

onMounted(() => {
  getBreadcrumb()
})

const router = useRouter()
const handleLink = (item) => {
  const { name } = item
  router.push({ name })
}
</script>

<style lang="scss" scoped>
.ggh-breadcrumb {
  padding: 5px;
}
</style>
