const validStyles = ['left', 'center', 'right']
const flexStyles = ['flex-start', 'center', 'flex-end']

export function validAlignment (style) {
  const idx = validStyles.lastIndexOf(style)
  if (idx === -1) {
    return flexStyles[0]
  }
  return flexStyles[idx]
}
