<template>
  <div class="ggh-char" @mouseenter="enter" @mouseleave="leave">
    <div
      v-if="validHighlight(highlight)"
      :class="{ dark: !hovered, light: hovered }"
    >
      <div class="core" />
    </div>
    <div class="core" v-else />
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUpdate, ref, toRefs, watch } from 'vue'
import { getCharData, validHighlight } from './charData'
import ErrorChar from './images/error.png'

// eslint-disable-next-line no-undef
const props = defineProps({
  char: {
    type: String,
    required: true
  },
  highlight: {
    type: String,
    default: 'none'
  },
  scale: {
    type: Number,
    default: 2
  },
  charHovered: {
    type: Boolean,
    default: false
  },
  hideLightWhenIdle: {
    type: Boolean,
    default: false
  }
})
const { char, highlight, scale } = toRefs(props)

const val = computed(() => {
  return getCharData(char.value)
})

const hovered = ref(props.charHovered)
watch(props, (newValue, oldValue) => {
  hovered.value = newValue.charHovered
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:charHovered'])

const enter = () => {
  hovered.value = true
  emit('update:charHovered', hovered.value)
}

const leave = () => {
  hovered.value = false
  emit('update:charHovered', hovered.value)
}

const theme = computed(() => {
  const res = {}
  res.height = imgHeight.value
  res.width = imgWidth.value
  if (val.value === -2) return res
  try {
    const bottom = require(`./images/${val.value}/bottom.png`)
    res.bottom = `url(${bottom})`
  } catch (err) {
    res.bottom = `url(${ErrorChar})`
  }
  if (!props.hideLightWhenIdle) {
    try {
      const dark = require(`./images/${val.value}/dark-${highlight.value}.png`)
      res.dark = `url(${dark})`
    } catch (err) {}
  }
  try {
    const light = require(`./images/${val.value}/light-${highlight.value}.png`)
    res.light = `url(${light})`
  } catch (err) {}
  try {
    const core = require(`./images/${val.value}/core.png`)
    res.core = `url(${core})`
  } catch (err) {}
  return res
})

const imgHeight = ref('auto')
const imgWidth = ref('auto')
const img = new Image()
const resize = (height, width) => {
  imgHeight.value = height * scale.value + 'px'
  imgWidth.value = width * scale.value + 'px'
}

const initialize = async () => {
  if (val.value === -2) {
    resize(20, 8)
  } else {
    return new Promise(resolve => {
      img.src = require(`./images/${val.value}/bottom.png`)
      resolve()
    })
      .then(() => {
        resize(img.height, img.width)
      })
      .catch(() => {
        resize(20, 20)
      })
  }
}

onBeforeMount(() => {
  nextTick(() => {
    initialize()
  })
})

onBeforeUpdate(() => {
  nextTick(() => {
    initialize()
  })
})
</script>

<style lang="scss" scoped>
div {
  height: v-bind("theme.height");
  width: v-bind("theme.width");
  background-size: cover;
}
.ggh-char {
  background-image: v-bind("theme.bottom");
}
.dark {
  background-image: v-bind("theme.dark");
}
.light {
  background-image: v-bind("theme.light");
}
.core {
  background-image: v-bind("theme.core");
}
</style>
