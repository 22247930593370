<template>
  <div class="option-item ggh-hover-brightened" />
</template>

<script setup>
import { computed } from 'vue'
// eslint-disable-next-line no-undef
const props = defineProps({
  image: {
    type: String,
    default: 'se'
  },
  scale: {
    type: Number,
    default: 1
  }
})

const theme = computed(() => {
  return {
    height: 27 * props.scale + 'px',
    width: 25 * props.scale + 'px',
    background: 'url(' + require(`../images/${props.image}.png`) + ')'
  }
})
</script>

<style lang="scss" scoped>
.option-item {
  height: v-bind("theme.height");
  width: v-bind("theme.width");
  background: v-bind("theme.background");
  background-size: cover;
}
</style>
