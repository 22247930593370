export default {
  'about-me': {
    intro0: '关于GOOGANGHEIM',
    intro1: 'Googangheim，由“Goo”、“gang”、“heim”三部分组成，其分别为一种鸽子的叫声拟声，英语中的“团体”，以及德语中的“家”。（不难发现这个名字其实neta了Guggenheim）',
    intro2: 'Googangheim是一个做白日梦的人建立的温馨小家。最初是作为一个游戏工作室的设想，但碍于LN的能力以及人生规划，其至今未能实现',
    intro3: '（可能永远不会实现了）',
    intro4: '。而目前这里作为一个开发当中的私人博客运作着，这里会不定期记录游戏开发、算法学习以及语言学研究等等方面的乱谈。',
    intro5: '这里欢迎所有友好的咕咕们，有什么天才的想法可以投递至',
    intro6: '（别问为什么没有网站邮箱）',
    title: '关于'
  },
  'asian-languages': {
    intro: '',
    title: '东亚'
  },
  'code-blaster': {
    intro: '这里囤积了不知道何时用的上的算法。。。',
    title: '代码爆破者'
  },
  'contact-me': {
    intro: '你可以在这里找到Googangheim的居民。',
    title: '联系'
  },
  'dot-to-pic': {
    intro: '',
    title: '点到图'
  },
  'european-languages': {
    intro: '',
    title: '欧洲'
  },
  'friend-links': {
    intro: 'Googangheim的朋友们。',
    title: '友链'
  },
  'game-dreamer': {
    intro: '这里记录了Googangheim的游戏大业的发展历程（雾',
    title: '游戏梦想家'
  },
  'googangsite-logs': {
    intro: '这里记录了Googangsite，也就是本网站更新的历程。',
    title: '咕哽站日志'
  },
  'linguistic-teatime': {
    intro: '这里集中了关于语言以及语言学的发现与研讨。',
    title: '语言学下午茶'
  },
  'pic-to-sprite': {
    intro: '',
    title: '图到精灵'
  },
  search: '搜索',
  'select-a-section': '选择一个板块',
  settings: {
    intro: '你可以在这里对页面的样式做出一些个性化调整。',
    title: '页面设置'
  },
  'small-tricks': {
    intro: '这里集中了Googangheim平时瞎捣鼓的小玩意。',
    title: '小玩意'
  },
  'something-else': {
    intro: '这里集中了非文章类型的功能项以及其他杂七杂八的东西。',
    title: '其它玩意'
  },
  'sprite-to-scene': {
    intro: '',
    title: '精灵到场景'
  },
  subscribe: '订阅',
  'project-zeclands': {
    title: '项目：席地亚'
  }
}
