<template>
  <div class="ggh-dialog full-window" v-if="visible">
    <ggh-mask stable v-model="visible" style="z-index: -1" />
    <ggh-tablet style="margin: auto" :reversed="reversed" :hide="hide" :height="height" :y-start="yStart"
      :y-duration="yDuration" :width="width" :x-start="xStart" :x-duration="xDuration">
      <div class="dialog-wrapper">
        <div class="dlg-edge" />
        <div class="dlg-border" />
        <div class="dlg">
          <ggh-string :string="title" :scale="2" v-if="gString" />
          <h1 v-else>{{ title }}</h1>
        </div>
        <div class="dlg">
          <slot />
        </div>
        <div class="dlg dlg-options">
          <ggh-button v-for="event in events" :key="event.name" @click="eventHandle(event.func)">{{ event.name }}
          </ggh-button>
        </div>
        <div class="dlg-border" />
        <div class="dlg-edge" />
      </div>
    </ggh-tablet>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, toRefs, watch } from 'vue'
import gghButton from '../Button'
import gghTablet from '../Tablet'
import gghMask from '../Mask'
import gghString from '../String'
// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'TITLE'
  },
  gString: {
    type: Boolean,
    default: false
  },
  events: {
    type: Array,
    default: () => []
  },
  cancel: {
    type: String
  },
  height: {
    type: String,
    default: 'auto'
  },
  yStart: {
    type: Number,
    default: 0
  },
  yDuration: {
    type: Number,
    default: 0
  },
  width: {
    type: String,
    default: 'auto'
  },
  xStart: {
    type: Number,
    default: 0
  },
  xDuration: {
    type: Number,
    default: 0
  },
  reversed: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Object,
    default: () => ({})
  }
})
// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue'])
const visible = ref(false)
watch(props, newValue => {
  visible.value = newValue.modelValue
})
watch(visible, newValue => {
  emit('update:modelValue', newValue)
})

// const blockVisible = ref(false)
// watch(props, newValue => {
//   if (newValue) {
//     visible.value = newValue
//   } else {
//     blockVisible.value = newValue
//   }
// })
// watch(blockVisible, newValue => {
//   if (visible.value !== blockVisible.value) {
//     setTimeout(() => {
//       visible.value = newValue
//     }, Math.max(props.heightDur, props.widthDur))
//   }
// })
// watch(visible, newValue => {
//   if (visible.value !== blockVisible.value) blockVisible.value = newValue
//   emit('update:modelValue', newValue)
// })

const { title, events, cancel } = toRefs(props)
const eventHandle = (event) => {
  if (event) event()
  visible.value = false
}

onBeforeMount(() => {
  if (cancel.value) {
    events.value.push({ name: cancel.value })
  }
})
</script>

<style lang="scss">
@import '@/assets/css';

.ggh-dialog {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: $ggh-dark;

  .dialog-wrapper {
    display: flex;
    flex-direction: column;

    div {
      overflow: hidden;
    }

    .dlg-border {
      height: 2px;
      width: 100%;
      background: $ggh-dark;
      margin: 4px 0;
    }

    .dlg-edge {
      height: 32px;
      width: 100%;
      transform: rotate(180deg);
      background: url("./ggh-dlg-edge.png");
      background-size: contain;
    }

    .dlg {
      margin: auto;
    }

    .dlg-options {
      display: flex;
      justify-content: space-around;
      width: 50%;

      .choice {
        padding: 8px;
        color: $ggh-dark;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
</style>
