function useComponents (app) {
  const requireComponents = require.context('./', true, /index.vue$/)
  requireComponents.keys().forEach(fileName => {
    // 重组组件名
    const reqName = fileName.substring(0, fileName.lastIndexOf('/')).replace('./', '').replace('/', '')
    // 组件实例
    const reqCom = requireComponents(fileName)
    // 组件挂载
    app.component(reqName, reqCom.default || reqCom)
  })
}

export default useComponents
