<template>
  <div class="layout" ref="layoutRef">
    <options :scale="titleScale" />
    <div class="layout-wrapper">
      <div class="left aside" v-show="showLeft && !$route.meta.noAside && ($route.name != 'index' || showRight)">
        <div v-for="item in leftSecs" :key="item.title">
          <section-item :scale="3" :title="item.title"
            @mouseenter="(selective = $t(item.title + '.title').toLocaleUpperCase()), (showHint = true)"
            @mouseleave="selective = null" style="margin: auto" />
        </div>
        <news-box :scale="3" v-if="$route.name != 'index'" />
      </div>
      <div class="center">
        <div class="layout-scrollbar" v-if="$route.name != 'index'">
          <ggh-title :style="{ zoom: titleScale / 2 }" />
          <content-box v-if="!$route.meta.noContent" />
          <comment-box v-if="!$route.meta.noContent && $route.params.article" style="margin: 50px 0" />
          <ggh-info />
        </div>
        <div class="layout-index" v-else>
          <div class="layout-logo" v-if="showLeft && showRight">
            <ggh-string string="googangheim" :scale="2 + showLeft + showRight" class="index-title" />
            <ggh-border width="66%" height="48px" class="index-hint" v-show="showHint || selective">
              {{ selective || $t("select-a-section").toLocaleUpperCase() }}
            </ggh-border>
          </div>
          <div class="layout-scrollbar narrow" v-else>
            <ggh-string string="googangheim" :scale="titleScale" class="index-title" :style="{marginTop: 15 * titleScale + 'px'}" />
            <section-item v-for="item in sections" :key="item.title" :scale="2" :title="item.title"
              style="margin: 15px"
              @mouseenter="(selective = $t(item.title + '.title').toLocaleUpperCase()), (showHint = true)"
              @mouseleave="selective = null" />
            <ggh-info />
          </div>
          <ggh-info abs="bottom" v-if="showLeft && showRight" />
        </div>
      </div>
      <div class="right aside" v-show="showRight && !$route.meta.noAside">
        <div v-for="item in rightSecs" :key="item.title">
          <section-item :scale="3" :title="item.title"
            @mouseenter="(selective = $t(item.title + '.title').toLocaleUpperCase()), (showHint = true)"
            @mouseleave="selective = null" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ContentBox from './components/ContentBox'
import CommentBox from './components/CommentBox'
import NewsBox from './components/NewsBox'
import Options from './components/Options'
import SectionItem from './components/SectionItem'
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import elementResizeDetectorMaker from 'element-resize-detector'

const sections = ref([
  { title: 'game-dreamer' },
  { title: 'linguistic-teatime' },
  { title: 'code-blaster' },
  { title: 'something-else' }
])

const leftSecs = ref([])
const rightSecs = ref([])

const changePositions = (name) => {
  leftSecs.value.length = 0
  rightSecs.value.length = 0
  if (!name || name === 'index') {
    leftSecs.value.push(sections.value[0])
    leftSecs.value.push(sections.value[1])
    rightSecs.value.push(sections.value[2])
    rightSecs.value.push(sections.value[3])
  } else {
    sections.value.forEach((ele, i) => {
      if (ele.title === name) {
        leftSecs.value.push(ele)
      } else {
        rightSecs.value.push(ele)
      }
    })
  }
}

const theme = computed(() => {
  return {
    leftWidth: showRight.value ? '30%' : '40%',
    centerWidth: !showRight.value ? (!showLeft.value ? '95%' : '50%') : '40%',
    rightWidth: '30%'
  }
})

const erd = elementResizeDetectorMaker()
const layoutRef = ref()
const showLeft = ref(true)
const showRight = ref(true)
const titleScale = ref(2)
const detectRef = () => {
  nextTick(() => {
    erd.listenTo(layoutRef.value, (element) => {
      if (element.clientWidth < 1680 && showRight.value) {
        showRight.value = false
      } else if (element.clientWidth >= 1680 && !showRight.value) {
        showRight.value = true
      }
      if (element.clientWidth < 1080 && showLeft.value) {
        showLeft.value = false
      } else if (element.clientWidth >= 1080 && !showLeft.value) {
        showLeft.value = true
      }
      titleScale.value = element.clientWidth < 560 ? 1 : 2
    })
  })
}

const selective = ref()
const showHint = ref(false)

watch(showHint, (newValue) => {
  setTimeout(() => {
    showHint.value = !newValue
  }, 800)
})

onBeforeMount(() => {
  changePositions(useRoute().matched[1].name)
  detectRef()
  showHint.value = true
})

onBeforeRouteUpdate(to => {
  changePositions(to.matched[1].name)
})
</script>

<style lang="scss" scoped>
@import '@/assets/css';

.layout {
  height: 100%;
  width: 100%;
  background: url(@/assets/images/ggh-background.gif);
  color: $ggh-light;
  overflow: hidden;

  .options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;
  }

  .layout-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .aside {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .left {
      width: v-bind("theme.leftWidth");
    }

    .center {
      width: v-bind("theme.centerWidth");

      .ggh-title {
        margin: 30px auto;
      }

      .layout-scrollbar {
        height: 100%;
        overflow: scroll;
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .narrow {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .layout-index {
        height: 100%;
        display: flex;
        justify-content: space-around;
        position: relative;
      }

      .layout-logo {
        width: 750px;
        height: 860px;
        margin: auto;
        background: url("@/assets/images/ggh-mega.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        justify-content: center;

        .index-title {
          position: absolute;
          bottom: 10%;
        }

        .index-hint {
          position: absolute;
          bottom: 2%;
          line-height: 28px;
        }

        .ggh-info {
          position: absolute;
          bottom: 0%;
        }
      }
    }

    .right {
      width: v-bind("theme.rightWidth");
    }
  }
}
</style>
