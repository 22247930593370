<template>
  <div class="ggh-tablet" :style="tabletStyle" :class="{ reverse: props.reversed }">
    <div class="expansion" :class="{ 'show-x': xExpanded, 'show-y': yExpanded }">
      <ggh-border height="100%" width="100%" :hide="props.hide">
        <div :style="coreStyle" ref="coreRef" :class="{ reverse: props.reversed }">
          <slot />
        </div>
      </ggh-border>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue'
import elementResizeDetectorMaker from 'element-resize-detector'
import gghBorder from '../Border'

// eslint-disable-next-line no-undef
const props = defineProps({
  height: {
    type: String
  },
  xStart: {
    type: Number,
    default: 0
  },
  xDuration: {
    type: Number,
    default: 0
  },
  width: {
    type: String
  },
  yStart: {
    type: Number,
    default: 0
  },
  yDuration: {
    type: Number,
    default: 0
  },
  reversed: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Object,
    default: () => ({})
  }
})

const coreRef = ref()
const refHeight = ref(0)
const refWidth = ref(0)
const erd = elementResizeDetectorMaker()
const detectRef = () => {
  nextTick(() => {
    erd.listenTo(coreRef.value, (element) => {
      refHeight.value = element.clientHeight
      refWidth.value = element.clientWidth
    })
  }).then(() => {
    expand(xExpanded, props.xStart, props.xDuration)
    expand(yExpanded, props.yStart, props.yDuration)
  })
}

const height = computed(() => {
  return !props.height
    ? refHeight.value
      ? `calc(${refHeight.value}px + 20px)`
      : 'auto'
    : props.height
})
const width = computed(() => {
  return !props.width
    ? refWidth.value
      ? `calc(${refWidth.value}px + 20px)`
      : 'auto'
    : props.width
})

const tabletStyle = computed(() => {
  return {
    height: height.value,
    width: width.value,
    transitionProperty: 'height, width',
    transitionDuration: props.yDuration + 'ms,' + props.xDuration + 'ms'
  }
})

const coreStyle = computed(() => {
  return {
    height: props.height && props.height.match('\\d+(px)|\\%') ? '100%' : 'fit-content',
    width: props.width && props.width.match('\\d+(px)|\\%') ? '100%' : 'fit-content',
    opacity: state.value >= 2 ? 1 : 0,
    transition: `opacity ${props.xDuration || props.yDuration ? '500' : '0'}ms`
  }
})

const theme = computed(() => {
  return {
    xDuration: props.xDuration + 'ms',
    yDuration: props.yDuration + 'ms'
  }
})

const state = ref(0)
const xExpanded = ref(false)
const yExpanded = ref(false)

const expand = async (axis, start, duration) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      axis.value = true
      resolve()
    }, start)
  }).then(() => {
    setTimeout(() => {
      state.value += 1
    }, duration)
  })
}

onMounted(async () => {
  detectRef()
})
</script>

<style lang="scss" scoped>
.expansion {
  height: 20px;
  width: 20px;
  transition-property: height, width;
  transition-duration: v-bind("theme.yDuration"), v-bind("theme.xDuration");
}

.show-x {
  width: 100%;
}

.show-y {
  height: 100%;
}

.reverse {
  transform: rotateY(180deg);
}
</style>
