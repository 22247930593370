import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/assets/css/index.scss'
import useComponents from '@/components'
import useLocales from '@/locales'

const app = createApp(App).use(store).use(router).use(ElementPlus)

useComponents(app)
useLocales(app)

app.mount('#app')
