import { h, render } from 'vue'
import GghMessageBoxCpn from './index.vue'

const instances = []

function GghMessageBox (options) {
  const container = document.createDocumentFragment()

  const vm = h(GghMessageBoxCpn, {
    ...options,
    onClose () {
      close(vm)
    },
    onDestroy () {
      render(null, container)
    }
  })

  render(vm, container)
  document.body.appendChild(container)

  instances.push(vm)
}

function close (vm) {
  const index = instances.findIndex(ins => ins === vm)
  if (index === -1) return

  instances.splice(index, 1)
}

export default GghMessageBox
