<template>
  <div class="search-option">
    <el-tooltip :content="$t('search').toLocaleUpperCase()" placement="bottom" effect="light">
      <items image="search" :scale="props.scale" @click="openSearch"> </items>
    </el-tooltip>
  </div>
</template>

<script setup>
import { GghMessageBox } from '@/components/ggh'

import items from './'
// eslint-disable-next-line no-undef
const props = defineProps({
  scale: {
    type: Number,
    default: 1
  }
})

const openSearch = () => {
  GghMessageBox({
    title: 'SEARCH',
    content: <div>IN DEVELOPMENT</div>,
    gString: true,
    cancel: 'CANCEL'
  })
}
</script>

<style lang="scss" scoped>

</style>
