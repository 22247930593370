<template>
  <div class="ggh-title">
    <ggh-string
      string="googangheim"
      highlight="down"
      :scale="props.scale"
      v-model:hovered="hovered"
      @click="$router.push('/')"
    />
    <div class="subtitle" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { validateTitle } from './titleData'

// eslint-disable-next-line no-undef
const props = defineProps({
  scale: {
    type: Number,
    default: 2
  }
})

const theme = computed(() => {
  return {
    width: 196 * props.scale + 'px',
    height: 29 * props.scale + 'px',
    subHeight: 9 * props.scale + 'px',
    subBottom: props.scale + 'px',
    subtitle: `url(${require('./images' + (validateTitle(route.path.substring(1)) ? route.path : '/something-else') + '.gif')})`
  }
})

const route = useRoute()

const hovered = ref(false)
</script>

<style lang="scss" scoped>
.ggh-title {
  height: v-bind("theme.height");
  width: v-bind("theme.width");
  background: url("./images/subtitle.png");
  background-size: cover;
  position: relative;
  .ggh-string {
    width: 100%;
    cursor: pointer;
  }
  .subtitle {
    height: v-bind("theme.subHeight");
    width: 100%;
    position: absolute;
    bottom: v-bind("theme.subBottom");
    background: v-bind("theme.subtitle");
    background-size: cover;
  }
}
</style>
