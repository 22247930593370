const loading = {
  state: {
    loading: true
  },
  getters: {
    loading: state => state.loading
  },
  mutations: {
    IS_LOADING: (state, loading) => {
      state.loading = loading
    }
  },
  actions: {
    isLoading: ({ commit }, loading) => {
      commit('IS_LOADING', loading)
    }
  }
}

export default loading
