export default {
  'about-me': {
    intro0: 'ABOUT GOOGANGHEIM',
    intro1: 'Googangheim consists of "Goo", "gang" and "heim", each of which indicates an onomatopoeia of a kind of doves "Googgers", "Group" in English and "Home" in German. (Easy to find out it\'s name imitates Mr. Guggenheim)',
    intro2: 'Googangheim is a haven founded by a daydreamer failure. It has been a suppose to be a indie game studio, which is still in suppose, attributing to LN\'s "achievelessness"',
    intro3: ' (it\'ll never comes true, probably)',
    intro4: '. And currently this site is running as a personal blog in development, where will randomly post some blahs about game dev, linguistics, etc.',
    intro5: 'Every friendly Googgers are welcomed and if you want to share wonderful ideas, please post them to',
    intro6: ' (don\'t ask where\'s our own mail)',
    title: 'About'
  },
  'asian-languages': {
    intro: '',
    title: 'Asians'
  },
  'code-blaster': {
    intro: 'Here stock up some algorithms never knows when to be used...',
    title: 'Code Blaster'
  },
  'contact-me': {
    intro: 'Here you can find the members of Googangheim.',
    title: 'Contact'
  },
  'dot-to-pic': {
    intro: '',
    title: 'Dot to Pic'
  },
  'european-languages': {
    intro: '',
    title: 'Europeans'
  },
  'friend-links': {
    intro: 'Here are Googangheim\'s friends',
    title: 'Links'
  },
  'game-dreamer': {
    intro: 'Here shows a great daydream of being a game creator in Googangheim.',
    title: 'Game Dreamer'
  },
  'googangsite-logs': {
    intro: 'Here store the records of Googangsite updates.',
    title: 'Log of Googangsite'
  },
  'linguistic-teatime': {
    intro: 'Here collect the discoveries and discusses about the languages around the world and the linguistics.',
    title: 'Linguistic Teatime'
  },
  'pic-to-sprite': {
    intro: '',
    title: 'Pic to Sprite'
  },
  search: 'Search',
  'select-a-section': 'Select a Section',
  settings: {
    intro: 'Here provide some options that allow you to customize this website.',
    title: 'Settings'
  },
  'small-tricks': {
    intro: 'Here collect some small tricks.',
    title: 'Small Tricks'
  },
  'something-else': {
    intro: 'Here collect some functional and miscellaneous stuffs.',
    title: 'Something Else'
  },
  'sprite-to-scene': {
    intro: '',
    title: 'Sprite to Scene'
  },
  subscribe: 'Subscribe',
  'project-zeclands': {
    title: 'Project: Zecci'
  }
}
